<div class="container-fluid">
  <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
  <!-- <div class="row">
    <div class="col-12">

      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/dashboard'"><i
                class="fa fa-home" aria-hidden="true"></i></a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/users'">Users</a>
          </li>
          <li class="breadcrumb-item">{{userData?.id? 'Update User': 'Create User'}}
          </li>

        </ol>

      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <form class="form-horizontal" [formGroup]="createUserForm">
            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="">First Name <span class="required">*</span></label>
                  <input type="text" class="form-control" id="firstName" formControlName="firstName" name="firstName"
                    placeholder="Enter Your First Name">
                  @if(submitted && usersControls['firstName'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['firstName'].errors['required']){
                    <div>{{prifileValidations.firstName.firstName_required_Validations}}</div>}

                    @if(usersControls['firstName'].errors['minlength']){
                    <div>{{prifileValidations.firstName.min_required_Validations}}</div>
                    }
                    @if(usersControls['firstName'].errors['maxlength']){
                    <div>{{prifileValidations.firstName.min_required_Validations}}</div>
                    }
                    @if(usersControls['firstName'].errors['pattern']){
                    <div>{{prifileValidations.firstName.allow_spaces}}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Last Name <span class="required">*</span></label>
                  <input type="text" class="form-control" id="lastName" formControlName="lastName"
                    placeholder="Enter Your Last Name">
                  @if(submitted && usersControls['lastName'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['lastName'].errors['required']){
                    <div>{{prifileValidations.lastName.lastName_required_Validations}}</div>}
                    @if(usersControls['lastName'].errors['minlength']){
                    <div>{{prifileValidations.lastName.min_required_Validations}}</div>
                    }
                    @if(usersControls['lastName'].errors['maxlength']){
                    <div>{{prifileValidations.lastName.min_required_Validations}}</div>
                    }
                    @if(usersControls['lastName'].errors['pattern']){
                    <div>{{prifileValidations.lastName.allow_spaces}}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Email <span class="required">*</span></label>
                  <input type="email" class="form-control" id="email" formControlName="email"
                    placeholder="Enter Your Email">
                  @if(submitted && usersControls['email'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['email'].errors['required']){
                    <div>{{prifileValidations.emmail_validation.email_required_validations}}</div>}
                    @if(usersControls['email'].errors['pattern']){
                    <div>{{prifileValidations.emmail_validation.email_pattern}}</div>}
                  </div>
                  }
                </div>
              </div>
              <!-- <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number <span class="required">*</span></label>
                  <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber"
                    placeholder="Enter Mobile Number">
                  @if(submitted && usersControls['phoneNumber'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['phoneNumber'].errors['required']){
                    <div>{{prifileValidations.mobile_validation.mobile_required_validations}}</div>}
                    @if(usersControls['phoneNumber'].errors['pattern']){
                    <div>{{prifileValidations.mobile_validation.mobile_mix_required_Validations}}</div>}


                  </div>}
                </div>
              </div> -->

              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number <span class="required">*</span></label>

                  <!-- Country Code Dropdown using ng-select -->
                  <div class="input-group">

                    <!-- ng-select with smaller width -->
                    <ng-select [items]="configData?.data?.countryCodes" bindLabel="name" bindValue="id"
                      formControlName="countryCode" placeholder="Select Country" class="ng-select-custom-size"
                      (change)="valiadationMobile($event)">

                      <!-- Template for displaying the selected item -->
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.shortName }} ({{ item.code }})</span>
                      </ng-template>

                      <!-- Template for displaying the options in the dropdown -->
                      <ng-template ng-option-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.code }})</span>
                      </ng-template>
                    </ng-select>

                    <!-- Mobile Number Input with larger width -->
                    <input type="text" class="form-control phone-number-input" id="phoneNumber"
                      formControlName="phoneNumber" placeholder="Enter Mobile Number">
                  </div>

                  <div *ngIf="submitted && createUserForm.controls['countryCode'].errors" class="invalid-feedback">
                    <div *ngIf="createUserForm.controls['countryCode'].errors['required']">
                      {{ prifileValidations.countryCode.mobile_code_required_validations }}
                    </div>
                  </div>
                  <!-- Validation Feedback -->
                  @if(submitted && usersControls['phoneNumber'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['phoneNumber'].errors['required']){
                    <div>{{prifileValidations.mobile_validation.mobile_required_validations}}</div>}
                    @if(usersControls['phoneNumber'].errors['pattern']){
                    <div>{{prifileValidations.mobile_validation.mobile_mix_required_Validations}}</div>}
                    <!--  @if(usersControls['phoneNumber'].errors['minlength']){
                    <div>{{valiadationMessage}}</div>
                    } -->
                    @if(usersControls['phoneNumber'].errors['maxlength']){
                    <div>{{valiadationMessage}}</div>

                    }
                  </div>}
                </div>
              </div>

              <!-- </div> -->



              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Role <span class="required">*</span></label>
                  <ng-select [items]="configData?.data?.roles" formControlName="role" (change)="valueChange($event)"
                    [multiple]="false" bindLabel="name" bindValue="id" [placeholder]="'Select...'"></ng-select>
                  @if((submitted)&& usersControls['role'].errors) {
                  <div class="invalid-feedback">
                    @if(usersControls['role'].errors['required']) {
                    <div>{{prifileValidations.role}}</div>}
                  </div>}
                </div>
              </div>
              <!-- <div class="col-lg-4">
                <div class="mb-5">
                  <label for="b">Password <span class="required" *ngIf="!userData?.id">*</span></label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password"
                      formControlName="password" placeholder="Enter Password">
                    <button class="btn btn-light ms-0" type="button" id="password-addon">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"
                        (click)="toggleFieldTextType()"></i></button>
                  </div>
                  @if(submitted && usersControls['password'].errors){
                  <div class="invalid-feedback">
                    @if(usersControls['password'].errors['required']){
                    <div>{{prifileValidations.password_validation.password_required_validations}}</div>}
                    @if(usersControls['password'].errors['pattern']){
                    <div>{{prifileValidations.password_validation.password_Invalid_pattern}}</div>
                    }
                  </div>}
                </div>
              </div> -->
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Project <span *ngIf="selectedRole==roleList.ADMIN || selectedRole==roleList.SUPERUSER" class="required">*</span></label>
                  <!-- <ng-select [items]="configData?.data?.projects" formControlName="projectIds"
                    [multiple]="multiProjectSelection" bindLabel="name" bindValue="id"
                    [placeholder]="'Select...'"></ng-select> -->
             












                    <ng-select [items]="configData?.data?.projects" placeholder="Select projects" formControlName="projectIds"  bindLabel="name"
                    bindValue="id"
                    (change)="onFilterProjectsChange($event)"
                    (add)="onSelectUtilityInWalletHistoryReport($event)"  [multiple]="multiProjectSelection"
                    [deselectOnClick]="true">

                    <!-- Template for displaying selected items with cross icon -->
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item?.name }}</span>
                      <!-- Cross icon to clear the selected item -->
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>

                    <!-- Multi-label template for displaying multiple selected items -->
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items.slice(0, 2)">
                        <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                        <!-- Cross icon for removing items -->
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                      </div>
                    </ng-template>
                  </ng-select>



                  @if((submitted)&& usersControls['projectIds'].errors) {
                  <div class="invalid-feedback">
                    @if(usersControls['projectIds'].errors['required']) {
                    <div>{{prifileValidations.project.project_required_validations}}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Status</label>&nbsp;&nbsp;
                  <ui-switch [ngClass]="{
                    'switch-checked':usersControls['status'].value ,
                      'switch-unchecked': !usersControls['status'].value
             }" formControlName="status" uncheckedLabel="Inactive" checkedLabel="Active" size="small"
                    class="me-1"></ui-switch>
                  <!-- <ui-switch color="#008000" formControlName="status" uncheckedLabel="Inactive" checkedLabel="Active"
                    size="small" class="me-1"></ui-switch> -->
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Locked</label>&nbsp;&nbsp;
                  <ui-switch [ngClass]="{
                       'switch-checked': formControl.value,
                         'switch-unchecked': !formControl.value
                }" formControlName="islocked" uncheckedLabel="No" checkedLabel="Yes" size="small"
                    class="me-1"></ui-switch>
                  <!--  <ui-switch ng-class="{'switch-checked':usersControls['role'].value ==true : false}"
                    formControlName="islocked" uncheckedLabel="No" checkedLabel="Yes" size="small"
                    class="me-1"></ui-switch> -->
                </div>
              </div>
            </div>
          </form>
          <ul class="list-inline wizard d-flex justify-content-end mb-0">
            @if(userData?.id){ <li class="previous list-inline-item"><button class="btn btn-primary"
                (click)="createUser('Update')">Update
              </button></li>}
            @else{
            <li class="next list-inline-item"><button class="btn btn-primary"
                (click)="createUser('Create')">Save</button></li>
            }
            <li class="previous list-inline-item" [routerLink]="'/users'"><button
                class="btn btn-outline-primary">Cancel</button></li>
          </ul>


        </div>
      </div>
    </div>
  </div>

</div>