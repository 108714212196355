import { createAction, props } from '@ngrx/store';
import { userListModel } from './userlist.model';

// fetch
export const getuserlist = createAction(
  '[Users] getMaster',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getuserlistSuccess = createAction(
  '[Users] fetch userlist success',
  props<{ getUserResponse: any }>()
);
export const getuserlistFailure = createAction(
  '[Users fetch userlist failed]',
  props<{ getUserError: any }>()
);

export const resetUserState = createAction('[users ] Reset User State');


//All users list

export const getAlluserlist = createAction(
  '[Users] getAllMaster',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAlluserlistSuccess = createAction(
  '[Users] fetch Alluserlist success',
  props<{ getAllUserResponse: any }>()
);
export const getAlluserlistFailure = createAction(
  '[Users fetch Alluserlist failed]',
  props<{ getAllUserError: any }>()
);
// user status changes
export const getUserStstusChnage = createAction(
  '[ConsumerStatus] fetch User',
  props<{ id: string; status: boolean }>()
);
export const getUserStatusSuccess = createAction(
  '[ConsumerStatus] fetch User success',
  props<{ getuserStatusResponse: any }>()
);
export const getUserSttausrFail = createAction(
  '[Consumer Status] fetch User failed',
  props<{ getUserStatusError: any }>()
);

//getUser By Id:
/* export const getuserlistById = createAction('[Users] getMaster', props<{ id: string }>());
export const getuserlistByIdSuccess = createAction('[Users] fetch userlist success', props<{ getUsersByIdResponse: any }>())
export const getuserlistByIdFailure = createAction('[Users fetch userlist failed]', props<{ getUsersByIdError: any }>())
 */
// Create User List
export const createUser = createAction(
  '[Users] CreateUser',
  props<{ userData: userListModel }>()
);
export const adduserlistSuccess = createAction(
  '[Users] CreateUser Success',
  props<{ createUserResponse: any }>()
);
export const adduserlistFailure = createAction(
  '[Users] CreateUser Failure',
  props<{ createUserError: any }>()
);

// Update Data
export const updateuserlist = createAction(
  '[Users] Update userlist',
  props<{ updatedData: userListModel; id: string }>()
);
export const updateuserlistSuccess = createAction(
  '[Users] Update userlist Success',
  props<{ updateUserResponse: any }>()
);
export const updateuserlistFailure = createAction(
  '[Users] Update userlist Failure',
  props<{ updateUserError: any }>()
);

// Delete Data
export const deleteuserlist = createAction(
  '[Users] Delete userlist',
  props<{ id: string }>()
);
export const deleteuserlistSuccess = createAction(
  '[Users] Delete userlist Success',
  props<{ deleteUserrResponse: any }>()
);
export const deleteuserlistFailure = createAction(
  '[Users] Delete userlist Failure',
  props<{ deleteUserError: any }>()
);
export const resetState = createAction('[users ] Reset State');

export const resetCreateState = createAction('[users ] Reset State');
