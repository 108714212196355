<div class="container-fluid">
    <div class="row">
        <div class="col-12">

            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="AllExport()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-6">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/dashboard'"><i
                                class="fa fa-home" aria-hidden="true"></i></a>
                    </li>
                    <li class="breadcrumb-item active">Users
                    </li>
                </ol>

            </div>
        </div>
        <div class="col-6"></div>
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid *ngIf="displaygrid" [data]="usergridData" [pageSize]="pageSize" [skip]="skip"
                            [sortable]="true" scrollable="scrollable" [selectable]="selectableSettings"
                            (selectionChange)="onSelect($event)" [pageable]="{
                        buttonCount: buttonCount,
                        info: info,
                        type: type,
                        pageSizes: pageSizes,
                        previousNext: previousNext
                      }" [reorderable]="true" filterable="menu" [filter]="usersState.filter" [sort]="usersState.sort"
                            [columnMenu]="{ filter: true }" (filterChange)="onUsersFilterChange($event)"
                            (sortChange)="onSortChange($event)" [resizable]="true" (pageChange)="userPageChange($event)"
                            (dataStateChange)="dataStateChange($event)" style="width: 100%;">

                            <ng-template kendoGridToolbarTemplate>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" (keyup.enter)="onSearchUsers()"
                                        [(ngModel)]="searchedValue" placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <kendo-grid-spacer></kendo-grid-spacer>
                                <button class="btn  btn-outline-primary btn-sm" (click)="createUser()">Create User
                                </button>

                                <button class="btn  btn-outline-primary btn-sm" (click)="onExport('Excel')">
                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                </button>

                                <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="onExport('Pdf')">
                                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                                <!-- <button class="btn btn-sm" kendoGridPDFCommand [svgIcon]="filePdfIcon">Export to
                                PDF</button> -->

                            </ng-template>

                            <!-- Pdf   start-->
                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                <kendo-grid
                                    [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata : usergridData.data"
                                    #pdfGrid>
                                    <kendo-grid-column field="firstName" title="First Name"></kendo-grid-column>
                                    <kendo-grid-column field="lastName" title="Last Name"></kendo-grid-column>
                                    <kendo-grid-column field="projectString" title="Projects"></kendo-grid-column>
                                    <kendo-grid-column field="email" title="Email"></kendo-grid-column>
                                    <kendo-grid-column field="countryMobile" title="Mobile Number"></kendo-grid-column>
                                    <kendo-grid-column field="role" title="Role"></kendo-grid-column>
                                    <kendo-grid-column field="statusUpdate" title="Status">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{ dataItem.statusUpdate }}
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-pdf fileName="Users.pdf" [allPages]="true" paperSize="A4"
                                        [landscape]="true"></kendo-grid-pdf>
                                    <kendo-grid-excel fileName="Users.xlsx"></kendo-grid-excel>
                                </kendo-grid>
                            </div>
                            <!-- Pdf Edrt-->
                            <!-- Pdf -->
                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <!--   <kendo-grid-column field="id" title="User ID"></kendo-grid-column> -->
                            <kendo-grid-column field="firstName" title="First Name"></kendo-grid-column>
                            <kendo-grid-column field="lastName" title="Last Name"></kendo-grid-column>
                        

                            <kendo-grid-column field="project" title="Projects">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                  <ng-container *ngIf="dataItem?.project?.length">
                                    <span>{{ dataItem?.project[0]?.name }}</span>
                              
                                    <span *ngIf="dataItem?.project.length > 1">
                                      <a href="javascript:void(0)" class="more-link"                           
                                        
                                         tooltipShowOn="click"
                                         style="color: blue; cursor: pointer;" (click)="displayMoreProjects(dataItem?.project)">
                                        +{{ dataItem?.project.length - 1 }} more
                                      </a>
                                    </span>
                                  </ng-container>
                              
                                  <span *ngIf="!dataItem?.project?.length">N/A</span>
                                </ng-template>
                            
                              </kendo-grid-column>
                              
                              
                              
                              


                            <kendo-grid-column field="email" title="Email"></kendo-grid-column>
                            <kendo-grid-column field="mobile" title="Mobile Number" [width]="100">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.countryCode}}{{dataItem.mobile}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="role" title="Role" [width]="120"></kendo-grid-column>


                            <kendo-grid-column title="Active/Inactive" field="status" [width]="100" filter="boolean">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <ui-switch [ngClass]="{
                                            'switch-checked':dataItem.status,
                                              'switch-unchecked': !dataItem.status }" [checked]="dataItem.status"
                                            uncheckedLabel="" checkedLabel="" size="small" class="me-1"
                                            (change)="ActivationChange($event,dataItem)"></ui-switch>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <!-- Edit User Icon -->
                                        <span kendoTooltip title="Go to User Edit" (click)="editUser(dataItem)"
                                            style="cursor: pointer;" class="me-3">
                                            <i class="fas fa-edit text-primary"></i>
                                        </span>

                                        <!-- Delete User Icon -->
                                        <!--  <span kendoTooltip title="Delete User" (click)="deleteMaster(dataItem)"
                                            style="cursor: pointer;">
                                            <i class="fas fa-trash-alt text-danger"></i>
                                        </span> -->
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>



                        </kendo-grid>

                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- <ng-template #profileModal let-modal>
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Project Details</h5>
          <button type="button" class="btn-close" (click)="closeProfileModal()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <ul *ngIf="selectedProjects?.length; else noData">
                <li *ngFor="let project of selectedProjects">{{ project.name }}</li>
              </ul>
              <ng-template #noData>
                <p>No Projects Available</p>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light me-1" (click)="closeProfileModal()">
            Close
          </button>
        </div>
      </ng-template> -->


      <ng-template #profileModal let-modal>
        <!-- <div class="modal-dialog modal-lg modal-dialog-scrollable"> -->
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              <h5 class="modal-title">Projects List</h5>
              <button type="button" class="btn-close" (click)="closeProfileModal()"></button>
            </div>
            <div class="modal-body" style="max-height: 400px; overflow-y: auto;">
              <div class="row">
                <ng-container *ngIf="selectedProjects?.length; else noData">
                  <div class="col-6 mb-2" *ngFor="let project of selectedProjects">
                    <div class="p-2 border rounded">
                      {{ project.name }}
                    </div>
                  </div>
                </ng-container>
                <ng-template #noData>
                  <p class="text-center w-100">No Projects Available</p>
                </ng-template>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light me-1" (click)="closeProfileModal()">
                Close
              </button>
            </div>
          </div>
        <!-- </div> -->
      </ng-template>
      
      




</div>