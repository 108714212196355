.invalid-feedback {
    display: block;
}

.required {
    color: red !important;
}

.kendo-label {
    margin-right: 4px;
    color: red !important;
}

.switch-unchecked .switch.switch-small {
    background-color: #FF0000;
    color: #FFFFFF;
}

/* Set custom width for ng-select */
.ng-select-custom-size {
    width: 120px;
    /* Adjust as needed for a smaller size */
}

/* Increase the width of the phone number input */
.phone-number-input {
    flex-grow: 1;
    /* This makes the input grow to fill the available space */
    width: auto;
}

.ng-select-custom-size {
    width: 140px;
}