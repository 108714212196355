import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from './../../../core/services/loader.service';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { Store, select } from '@ngrx/store';
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createUser,
  resetCreateState,
  updateuserlist,
} from '../../../store/UserList/userlist.action';
import { Validations } from '../../../shared/constants/validations';
import { createMasterResponse } from '../../../store/Projects/projects-selector';
import { createUserResponse } from '../../../store/UserList/userlist-selector';
import { Roles } from '../../../shared/constants/enum';

@Component({
  selector: 'app-rp-create-user',
  templateUrl: './rp-create-user.component.html',
  styleUrl: './rp-create-user.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RpCreateUserComponent {
  createUserForm!: UntypedFormGroup;
  submitted: boolean = false;
  configData: any;
  tempprojectsList: any = [];
  userData: any;
  fieldTextType!: boolean;
  public isChecked = false;
  formControl: FormControl = new FormControl();
  prifileValidations = Validations.ProfilePage;
  selectedProject: any;
  isDisabled: boolean = true;
  pwdRegex = '^.*(?=.{8,})(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[A-Z]).*$';
  emailRgex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
  nameRegex = '^[A-Za-z]+( [A-Za-z]+)?$';
  projectlist: any[] = [];
  roles: any[] = [];
  roleList = Roles;
  breadCrumbItems: Array<{}> | undefined;
  public disabledMultiSelect = false; // Toggle for disabling MultiSelect
  selectedRole: string = '';
  multiProjectSelection: boolean = false;
  PrimaryCountryCode: any
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createUsersForm();
    if (
      history.state.userData !== undefined &&
      history.state.userData != null
    ) {
      this.userData = history.state.userData;
      console.log(this.userData)
      this.PrimaryCountryCode = this.userData.countryCode
      // this.userData.project = [this.userData.project];
      this.breadCrumbItems = [
        { label: 'User', url: 'users', active: true },
        { label: 'Edit User' },
      ];
    } else {
      this.breadCrumbItems = [
        { label: 'User', url: 'users', active: true },
        { label: 'Create User' },
      ];
    }
  }
  public checked = true;
  ngOnInit(): void {
    if (this.userData?.role) {
      if (this.userData?.role == 'SuperAdmin') {
        this.createUserForm.controls['projectIds'].disable();
      } else if (this.userData?.role == 'SuperUser') {
        this.createUserForm.controls['projectIds'].enable();
        this.multiProjectSelection = true
      } else {
        this.createUserForm.controls['projectIds'].disable();
        this.usersControls['projectIds'].setValidators(Validators.required);
        this.usersControls['projectIds'].updateValueAndValidity();
        this.multiProjectSelection = false;
      }
    }


    this.loaderService.setLoading(true);
    this.formControl = this.createUserForm.get('islocked') as FormControl;
    this.getAllConfigData();

    this.createUserForm.get('role')?.valueChanges.subscribe((value) => {
      const selectedRole = this.configData?.data?.roles.find(
        (role: any) => role.id === value
      );
      this.selectedRole = selectedRole.name;
      if (selectedRole.name == this.roleList.ADMIN) {
        this.createUserForm
          .get('projectIds')
          ?.setValidators([Validators.required]); // Alerts the name of the selected role
      } else if (selectedRole.name == this.roleList.SUPERADMIN) {
        this.createUserForm.get('projectIds')?.clearValidators();
      }
      this.createUserForm.get('projectIds')?.updateValueAndValidity();
    });
  }


  onCountryCodeChange(event: any): void {
    console.log(event);

    // this.selectedCountryCode = event.target.value;
  }
  createUsersForm() {
    this.createUserForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      email: ['', [Validators.required, Validators.pattern(this.emailRgex)]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/),
        ],
      ],
      countryCode: [null, [Validators.required]],
      // password: [''],
      role: ['', [Validators.required]],
      projectIds: [null],
      status: [false],
      islocked: [false],
    });
  }
  valueChange(value: any): void {
    console.log('valueChange', value);
    this.selectedProject = value;
    this.multiProjectSelection = false;
    if (value.name == 'SuperAdmin') {
      this.usersControls['projectIds'].reset();
      this.usersControls['projectIds'].clearValidators();
      this.usersControls['projectIds'].updateValueAndValidity();
      this.createUserForm.controls['projectIds'].disable();
    } else {
      this.usersControls['projectIds'].setValidators(Validators.required);
      this.usersControls['projectIds'].updateValueAndValidity();
      this.createUserForm.controls['projectIds'].enable();
      this.configData.data.projects = (this.configData.data.projects || []).filter(
        (project: any) => project.id !== 'all'
      );
      if (value.name == 'SuperUser') {
        this.multiProjectSelection = true;
        this.configData.data.projects = [
          {
            id: 'all',
            name: 'All projects',
          },
          ...(this.configData.data?.projects || []), // Use an empty array if data.data.utilities is undefined
        ];
      }

    }
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  selectedCountyCode: any;
  valiadationMessage: String = ''
  valiadationMobile(event: any) {
    this.PrimaryCountryCode = event.code
    console.log(event)
    this.selectedCountyCode = event.code;
    if (event.code == '+91') {
      this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
      this.usersControls['phoneNumber'].updateValueAndValidity();
      this.valiadationMessage = 'Mobile Number should contain 10 digits'
    } else {
      this.usersControls['phoneNumber'].clearValidators();
      this.usersControls['phoneNumber'].updateValueAndValidity();

    }

    switch (this.selectedCountyCode) {
      /* case '+971'://United Arab Emirates
        this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(9), Validators.minLength(9), Validators.pattern(/^05[0-9]{8}$/),]);
        this.usersControls['phoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'Mobile Number Should Conatines min 9 and max 9 characters'

        break; */
      /* case '+91': // India
        this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
        this.usersControls['phoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'Mobile Number should contain 10 digits'
        break; */
      /*  case '+86'://China
         this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(11), Validators.minLength(11), Validators.pattern(/^1[3-9]\d{9}$/),]);
         this.usersControls['phoneNumber'].updateValueAndValidity();
         this.valiadationMessage = 'Mobile Number Should Conatines min 11 and max 11 characters'
         break; */
      /*  case '+1'://Usa
         this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(14), Validators.minLength(10), Validators.pattern(/^\(?([2-9]{1}[0-9]{2})\)?[-\s]?([2-9]{1}[0-9]{2})[-\s]?([0-9]{4})$/),]);
         this.usersControls['phoneNumber'].updateValueAndValidity();
         this.valiadationMessage = 'Mobile number must be at least 10 digits'
         break; */

    }
  }


  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
        if(this.multiProjectSelection){
        this.configData.data.projects = [
          {
            id: 'all',
            name: 'All projects',
          },
          ...(data.data?.projects || []), // Use an empty array if data.data.utilities is undefined
        ];
      }



        if (this.userData?.id == undefined) {
          this.loaderService.setLoading(false);
        }
        this.tempprojectsList = this.configData?.data?.projects;
        this.projectlist = this.configData?.data?.projects;
        this.roles = this.configData?.data?.roles;

        console.log(this.userData);
        console.log(this.userData?.project?.map((p: any) => p.id));
        var selectedProjects = Array.isArray(this.userData?.project)
          ? this.userData.project.map((p: any) => p.id)
          : [this.userData?.project?.id];

                    // Check if selectedProjects length matches projects length (excluding 'all')
          const totalProjectsWithoutAll = this.configData?.data?.projects.filter((p: any) => p.id !== 'all').length;

          if (selectedProjects.length === totalProjectsWithoutAll) {
            selectedProjects = ['all'];
          }


        if (this.userData.firstName) {
          this.createUserForm.controls['role'].disable();
        }

        setTimeout(() => {
          if (Object.keys(this.userData ?? {}).length > 0) {
            this.createUserForm.patchValue({
              firstName: this.userData?.firstName ? this.userData.firstName : '',
              lastName: this.userData?.lastName ? this.userData.lastName : '',
              email: this.userData?.email ? this.userData.email : '',
              role: this.userData?.roleId ? this.userData.roleId : '',
              countryCode: this.userData?.countryCodeId ? this.userData.countryCodeId : '',
              phoneNumber: this.userData?.mobile ? this.userData.mobile : '', // password: this.userData?.password ? this.userData.password : '',
              projectIds: this.multiProjectSelection ? selectedProjects : selectedProjects[0],
              status: this.userData?.status == true ? true : false,
              islocked: this.userData?.isLocked ? this.userData.isLocked : false,
            });
          }

        }, 100)
        console.log('selectedProjects', selectedProjects);
        // if (selectedProjects?.length > 0) {
        //   this.createUserForm.get('projectIds')?.setValue(selectedProjects);
        // }

        // Patch ProjectIds FormArray
        // const projectArray = this.createUserForm.get('projectIds') as FormArray;
        // projectArray.clear(); // Clear existing items
        // selectedProjects.forEach((id:any) => projectArray.push(new FormControl(id)));

        console.log("sssssssssssssssssss", this.createUserForm.value);


        this.configData?.data?.countryCodes.forEach((code: any) => {
          if (this.userData.countryCodeId == code.id) {
            this.valiadationMobile(code);
            console.log("dhsad")
          }

        })
        this.loaderService.setLoading(false);
        // setTimeout(() => { console.log("eawwwwwwwwwwwwwwwwwwwwww", this.createUserForm.value); }, 2000)
      }
      // console.log('Projects', this.createUserForm.value);
      // console.log('Types', this.configData.data.types);
    });
  }

  get usersControls() {
    return this.createUserForm.controls;
  }

  public onFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectlist = this.tempprojectsList.filter(contains(searchTerm));
  }

  userProjectsFilter(value: any) {
    this.projectlist = this.tempprojectsList.filter(
      (s: { name: string }) =>
        s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  public onFilterProjectsChange(searchTerm: any): void {
    // Convert searchTerm to a string, default to an empty string if it's not valid
    const term = typeof searchTerm === 'string' ? searchTerm : '';
    const contains = (value: string) => (item: { name: string; id: string }) =>
      item.name.toLowerCase().includes(value.toLowerCase());
  
    // Check if configData, data, and projects exist before filtering
    if (this.configData?.data?.projects) {
      this.configData.data.projects = this.configData.data.projects.filter(contains(term));
    }
  }

  public onSelectUtilityInWalletHistoryReport(selectedItems: string[]): void {
    // Ensure selectedItems is an array
    const itemsArray = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
    // If "All Utilities" is selected, deselect all other utilities
    if (itemsArray.some((item: any) => item.id === 'all')) {
      this.createUserForm.value.projectIds = ['all'];
      this.createUserForm.patchValue({ projectIds: ['all'] });
    } else {
      // If "All Utilities" is currently selected and another item is selected, remove "All Utilities"
      if (this.createUserForm.value.projectIds.includes('all')) {
        
        const filteredProjectIds = this.createUserForm.value.projectIds.filter((item: any) => item !== 'all');
        this.createUserForm.patchValue({ projectIds: filteredProjectIds });
      } else {
        // Check if all utilities are selected
        const totalUtilityCount = this.configData?.data.projectss.length || 0;
        if (this.createUserForm.value.projectIds.length === totalUtilityCount - 1) {
          this.createUserForm.value.projectIds = ['all']; // Select "All Utilities" if all individual utilities are selected
          this.createUserForm.patchValue({ projectIds: ['all'] });
        }
      }
    }
  }
  


  createUser(type: string): void {

    let projectIds = this.createUserForm.get('projectIds')?.value;
    if (!Array.isArray(projectIds)) {
      projectIds = [projectIds];  // Convert to an array if not already
      this.createUserForm.value.projectIds = [...projectIds]
    }
    if (projectIds.includes('all')) {
      projectIds = this.configData?.data?.projects
        .filter((item: any) => item.id !== 'all')
        .map((item: any) => item.id);
    }
    this.submitted = true;
    if (this.createUserForm.valid) {
      this.loaderService.setLoading(true);
      console.log(this.createUserForm.value);

      var request: any = {
        firstName: this.createUserForm.get('firstName')?.value,
        lastName: this.createUserForm.get('lastName')?.value,
        email: this.createUserForm.get('email')?.value,
        roleId: this.createUserForm.get('role')?.value,
        // password: this.createUserForm.get('password')?.value,
        countryCode: this.createUserForm.get('countryCode')?.value,
        projectId: projectIds,
        phoneNumber: this.createUserForm.get('phoneNumber')?.value,
        status: this.createUserForm.get('status')?.value,
        islocked: this.createUserForm.get('islocked')?.value,
        PrimaryCountryCode: this.PrimaryCountryCode
      };
      request.projectId = request.projectId == '' ? null : request.projectId;

      console.log(request);
      if (type == 'Create') {
        console.log(request);
        this.store.dispatch(createUser({ userData: request }));
        // this.loaderService.setLoading(false);
      } else if (type == 'Update') {

        const deletedItems = this.userData.project.filter((item: any) => !this.createUserForm.value.projectIds.includes(item.id)).map((item: any) => item.id);;
        // console.log("Removed Projects:", deletedItems);
        request.deletedProjectId = deletedItems;
        // return
        this.store.dispatch(
          updateuserlist({ updatedData: request, id: this.userData.id })
        );
      }
    } else {
      return;
    }
  }
}
